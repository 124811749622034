import React from "react";
import mainlogo from "../assets/images/mainlogo.png";
import call from "../assets/images/call.png";

const Contact = () => {
  return (
    <>
      <section className="myheader">
        <div className="container">
          <div className="row">
            <div className="col-xxl-7 col-xl-7 col-md-6 col-12">
              <div className="mylogo">
                <a href="/">
                  <img src={mainlogo} className="mainlogo" alt="logo" />
                </a>
              </div>
            </div>
            <div className="col-xxl-5 col-xl-5 col-md-6 col-12">
              <div className="calling">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img src={call} className="chatmsg" alt="" />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Call Us Now</h3>
                    <p>
                      <a href="tel:0333 000 000">0333 000 000</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="main-section">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-md-12 col-12">
              <div className="content-box">
                <h1>We’re here to assist you </h1>
                <p className="accident">
                  Connect with our dedicated team to start your no-fault
                  accident claim process.{" "}
                </p>
                <h3 className="hr-lines">Get in touch with us</h3>
                <div className="settlement">
                  <div className="cont-form" id="contv">
                    <form
                      action="Service.php"
                      method="post"
                      enctype="multipart/form-data"
                    >
                      <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            placeholder="Name"
                            required
                          />
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            placeholder="Email"
                            required
                          />
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                          <input
                            type="tel"
                            name="phone"
                            className="form-control"
                            placeholder="Phone"
                            required
                          />
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                          <input
                            type="text"
                            name="subject"
                            className="form-control"
                            placeholder="Subject"
                            required
                          />
                        </div>
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                          <textarea
                            id="message"
                            title="Message"
                            name="message"
                            placeholder="How can we help you?"
                            required
                          />
                          <div className="form-btn">
                            <input
                              type="submit"
                              className="form-rol"
                              defaultValue="send"
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="bootom-bnr">
                <p>
                  We value your privacy. For more information, please refer to
                  our{" "}
                  <span className="colorspan">
                    <a className="contacted" href="/privacy-policy">
                      Privacy Policy
                    </a>{" "}
                    &{" "}
                    <a className="contacted" href="/terms-conditions">
                      Terms
                    </a>{" "}
                    of Use.
                    <br />{" "}
                    <a className="contacted" href="/Contact">
                      Get in touch with us{" "}
                    </a>
                  </span>
                  to discuss your claim with an experienced accident claim
                  specialist.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="copy-right01">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <p className="colpyy">
                © Copyright 2024 <a href="/">Insurance claims online</a>. All
                Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
