import React from "react";
import carinsurancelogo from "../assets/images/carinsurancelogo.png";
import call from "../assets/images/call.png";

const Home = () => {
  return (
    <>
      <section className="myheader">
        <div className="container">
          <div className="row">
            <div className="col-xxl-7 col-xl-7 col-md-6 col-12">
              <div className="mylogo">
                <a href="/">
                  <img src={carinsurancelogo} className="mainlogo" alt="logo" />
                </a>
              </div>
            </div>
            <div className="col-xxl-5 col-xl-5 col-md-6 col-12">
              <div className="calling">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img src={call} className="chatmsg" alt="" />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Call Us Now</h3>
                    <p>
                      <a href="tel:0800 654 6530">0800 654 6530</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="main-section">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-md-12 col-12">
              <div className="content-box">
                <h1> Car accident claims assistance UK </h1>
                <p className="accident">
                 Get expert help from our UK based accident management team regarding your claim.{" "}
                </p>
                <h3 className="hr-lines">* No excess payments</h3>
                <div className="settlement">
                  <div className="row">
                    <div
                      className="col-xxl-6 col-xl-6 col-md-6 col-12"
                      id="colborder"
                    >
                      <div className="cta1">
                        <h5 className="Road">Start your free claim </h5>
                        <p className="small">
                          Speak to our claim experts today.  
                        </p>
                        <div className="box-call-body">
                          <a
                            className="btn btn-circle btn-icon btn-shadow"
                            href="tel:#"
                          >
                            <img src={call} className="housephone" alt="" />
                          </a>
                          <div className="box-call-info">
                            <h3>
                              <a
                                className="d-inline-block"
                                href="tel:0800 654 6530"
                              >
                               0800 654 6530
                               
                              </a>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-xxl-6 col-xl-6 col-md-6 col-12"
                      id="colborder"
                    >
                      <div className="cta2">
                        <h5 className="Road">Get emergency support </h5>
                        <p className="small">
                          Access 24/7 roadside assistance 
                        </p>
                        <div className="box-call-body">
                          <a
                            className="btn btn-circle btn-icon btn-shadow"
                            href="tel:#"
                          >
                            <img src={call} className="housephone" alt="" />
                          </a>
                          <div className="box-call-info">
                            <h3>
                              <a
                                className="d-inline-block"
                                href="tel: 0800 654 6530"
                              >
                                 0800 654 6530
                              </a>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bootom-bnr">
                <p>
                  We value your privacy. For more information, please refer to
                  our{" "}
                  <span className="colorspan">
                    <a className="contacted" href="/privacy-policy">
                      Privacy Policy
                    </a>{" "}
                    &{" "}
                    <a className="contacted" href="/terms-conditions">
                      Terms of Use
                    </a>{" "}
                    .
                    <br />{" "}
                    <a className="contacted" href="tel: 0800 654 6530">
                      Get in touch with us{" "}
                    </a>
                  </span>
                  to discuss your claim with an experienced accident claim
                  specialist.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="copy-right01">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <p className="colpyy">
              © Copyright 2024 <a href="https://car-insurance-services.co.uk">car-insurance-services.co.uk</a>. All
              Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
