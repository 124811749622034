import React from "react";
import carinsurancelogo from "../assets/images/carinsurancelogo.png";
import call from "../assets/images/call.png";

const Termsconditions = () => {
  return (
    <>
      <section className="myheader">
        <div className="container">
          <div className="row">
            <div className="col-xxl-7 col-xl-7 col-md-6 col-12">
              <div className="mylogo">
                <a href="/">
                  <img src={carinsurancelogo} className="mainlogo" alt="logo" />
                </a>
              </div>
            </div>
            <div className="col-xxl-5 col-xl-5 col-md-6 col-12">
              <div className="calling">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img src={call} className="chatmsg" alt="" />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Call Us Now</h3>
                    <p>
                      <a href="tel:0800 654 6530">0800 654 6530</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="privacy-policy">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="headw">
                <h2>Terms & Conditions</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="main-content">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h3>Terms of Use </h3>
              <p>
                Please read these terms of use carefully before you start using
                our website. By using our website, you consent to accept these
                terms of use and agree to abide by them. If you do not agree to
                these terms of use, please refrain from using our website.
              </p>

              <h3>Accessing our website</h3>
              <p>
                Your access to and use of our website is provided on a temporary
                basis. In our sole discretion, we reserve the right to withdraw
                or amend the services provided on the website without any prior
                notice. If for some reason, our website is unavailable, we won’t
                be liable for it.
              </p>

              <h3>Intellectual property rights</h3>
              <p>
                The material and content accessible from this website is
                licensed, or controlled by us. We are the owner or the licensee
                of all intellectual property rights on our website and in the
                material published on it. The content is protected by copyright,
                trademark and other intellectual property rights. Accordingly,
                the content cannot be copied, distributed, republished,
                uploaded, posted, or transmitted in any way without our prior
                written consent. You may print out a copy of the content solely
                for your personal use and reference.{" "}
              </p>

              <p>
                You are granted a limited, non-exclusive, non-transferable
                licence to access and use the website for personal,
                non-commercial purposes. Any other use of the content and
                materials on this website, including commercial use,
                reproduction, modification, distribution, or republication,
                without our prior written permission is strictly prohibited. We
                reserve the right to terminate or restrict a user’s access to
                the website and its services if there is a violation of these
                intellectual property rights terms.
              </p>

              <h3>Disclaimer </h3>
              <p>
                The materials contained on our website are provided for general
                information purposes only and do not claim to be or constitute
                legal or other professional advice and shall not be relied upon
                as such. We make no representations, assurances, or warranties
                to the accuracy, completeness or suitability of the information
                on this website. We hereby expressly exclude, to the fullest
                extent permissible by law, all terms, conditions and warranties
                implied by statutes, common law, or equitable principles for
                ourselves and any third parties associated with us.{" "}
              </p>
              <p>
                We do not accept any responsibility for any loss which may arise
                from accessing or relying on the information on this website and
                to the fullest extent permitted by English law, we exclude all
                liability for loss or damages direct or indirect arising from
                the use of this website.
              </p>

              <h3>Links from our Website</h3>
              <p>
                Our website may contain links to third-party websites or
                resources. These links are provided for your convenience and
                informational purposes only and do not signify that we endorse
                the content, products, services, or the website itself. We have
                no control over the contents of those websites or resources and
                accept no responsibility for them or for any loss or damage that
                may arise from your use of them. The third-party websites have
                their own terms and conditions and privacy policies, and we do
                not accept any responsibility or liability for these policies.
                You’re advised to review the terms and conditions and privacy
                policies of any third-party websites you visit.
              </p>

              <h3>Our liability </h3>
              <p>
                To the fullest extent permitted by law, we and our affiliates,
                officers, directors, employees, agents, or partners shall not be
                liable for any direct, indirect, incidental, special,
                consequential, or punitive damages, including but not limited
                to, loss of profits, data, use, goodwill, or other intangible
                losses, resulting from:.
              </p>

              <ul>
                <li>The use or inability to use our website.</li>
                <li>
                  Unauthorised access to or alteration of your transmissions or
                  data.
                </li>
                <li>
                  Statements or conduct of any third party on our website.{" "}
                </li>
                <li>Any other matter relating to our website.</li>
              </ul>
              <p>
                We do not exclude or limit in any way our liability to you where
                it would be unlawful to do so. This includes liability for death
                or personal injury caused by our negligence or the negligence of
                our employees, agents, or subcontractors, and for fraud or
                fraudulent misrepresentation.
              </p>

              <h3>Indemnification</h3>
              <p>
                You agree to indemnify, defend, and hold harmless us and our
                affiliates, officers, directors, employees, agents, or partners,
                from and against any and all claims, liabilities, damages,
                losses, costs, expenses, and fees, including reasonable
                attorneys' fees, arising from:
              </p>

              <ul>
                <li>Your use of our website.</li>
                <li>Your violation of these terms and conditions.</li>
                <li>Your violation of any rights of any third party.</li>
                <li>Any content you post or share on our website.</li>
              </ul>
              <h3>Viruses, hacking and offences</h3>
              <p>By using our website, you agree not to: </p>
              <ul>
                <li>
                  Intentionally introduce viruses, trojans, worms, logic bombs,
                  or other malicious or technologically detrimental material to
                  our website.
                </li>
                <li>
                  Obtain unauthorised access to our website, the server on which
                  our website resides, or any server, computer, or database
                  connected to our website.
                </li>
                <li>
                  Launch a denial-of-service or distributed denial-of-service
                  attack against our website.
                </li>
              </ul>

              <p>
                Any breach of this provision may constitute a criminal offence
                under relevant laws. We will report any such breach to the
                relevant law enforcement authorities and cooperate with them to
                disclose your identity. In the event of such a breach, your
                right to use our website will cease immediately.
              </p>

              <h3>Governing law</h3>
              <p>
                The English courts will have non-exclusive jurisdiction over any
                claim arising from, or related to, a visit to our website.
              </p>

              <p>
                These terms of use and any dispute or claim arising out of or in
                connection with them or their subject matter or formation,
                including non-contractual disputes or claims shall be governed
                by and construed in accordance with the law of England and
                Wales.
              </p>

              <h3>Changes to the Terms of Use </h3>
              <p>
                We may modify these terms of use at any time. You’re advised to
                periodically review this page to stay informed about the changes
                in our terms of use.{" "}
              </p>
              <h3>Contact us</h3>
              <p>
                In the event of any questions or queries about the terms of use,
                kindly contact us at <a href="mailto:info@car-insurance-services.co.uk">info@car-insurance-services.co.uk</a>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="copy-right01">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <p className="colpyy">
              © Copyright 2024 <a href="https://car-insurance-services.co.uk">car-insurance-services.co.uk</a>. All
              Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Termsconditions;
