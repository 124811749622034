import React from "react";
import carinsurancelogo from "../assets/images/carinsurancelogo.png";
import call from "../assets/images/call.png";

const Privacypolicy = () => {
  return (
    <>
      <section className="myheader">
        <div className="container">
          <div className="row">
            <div className="col-xxl-7 col-xl-7 col-md-6 col-12">
              <div className="mylogo">
                <a href="/">
                  <img src={carinsurancelogo} className="mainlogo" alt="logo" />
                </a>
              </div>
            </div>
            <div className="col-xxl-5 col-xl-5 col-md-6 col-12">
              <div className="calling">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img src={call} className="chatmsg" alt="" />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Call Us Now</h3>
                    <p>
                      <a href="tel:0800 654 6530">0800 654 6530</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="privacy-policy">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="headw">
                <h2>Privacy Policy</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="main-content">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h3>Privacy Policy</h3>
              <p>
                This Privacy Policy sets out how we use and protect any
                information that you provide us when you use our website.
              </p>
              <p>
                We are committed to safeguarding your personal information.
                Should we ask you to provide certain information by which you
                can be identified when using this website, you can rest assured
                your information will be used and processed in accordance with
                this Privacy Policy. Before using our website, please read our
                Privacy Policy. By using our website, you consent to the use of
                your personal information as outlined in this Privacy Policy.{" "}
              </p>

              <h3>What information we collect </h3>
              <p>
                We will collect your personal information when you register on
                our website or inquire about our services. The personal
                information we collect may include your name, name, email
                address, telephone number, driving licence information and NI
                number details. We may also collect non-identifiable
                information, including your IP address, device type, operating
                system, browser type, and usage information.{" "}
              </p>

              <h3>How we use your personal information</h3>
              <p>
                We may use the information we hold about you for the following
                purposes:{" "}
              </p>
              <ul>
                <li>
                  To provide you with marketing information about our products
                  and services.
                </li>
                <li>
                  To perform our contract with you and provide you with the
                  requested services.
                </li>
                <li>To improve our products and services.</li>
                <li>
                  To send promotional emails about new products, special offers
                  or other information, which might be of interest to you.
                </li>
                <li>To contact you for market research purposes.</li>
                <li>To comply with legal obligations.</li>
                <li>To pursue our legitimate interests.</li>
                <li>To customise the website according to your interests.</li>
              </ul>
              <h3>With whom we share your personal information</h3>
              <p>
                We may share your data with our third-party service providers
                for the purposes of providing services. These third parties may
                include:
              </p>
              <ul>
                <li>
                  Companies or associates involved in facilitating your claim.
                </li>
                <li>
                  Third parties involved in the claim, their insurer, solicitor
                  or representative.
                </li>
                <li>
                  Medical professionals, law enforcement agencies or other
                  investigators.
                </li>
                <li>Assigned engineers.</li>
                <li>Our panel of solicitors.</li>
                <li>
                  Repair centres or garages, responsible for completing repairs.
                </li>
              </ul>
              <h3>How we keep your information secure </h3>
              <p>
                We have implemented appropriate technical and organisational
                measures to protect the personal information we hold about you.
                However, the transmission of information via the Internet is not
                completely secure. Although we will do our best to safeguard
                your personal information, we cannot guarantee the security of
                the information transmitted via the Internet. Any such
                transmission is at your own risk. Once we have received your
                information, we will implement robust procedures and security
                features to prevent unauthorised access and fraud.{" "}
              </p>
              <h3>Your rights regarding your personal information </h3>
              <p>
                There are several ways in which you can control how we use your
                personal information. Your rights regarding your personal
                information include:
              </p>
              <ul>
                <li>Right to ask us for a copy of your personal data.</li>
                <li>
                  Right to ask us to correct or complete your personal
                  information.
                </li>
                <li>Right to ask us to erase your personal information.</li>
                <li>
                  Right to ask us to stop processing your personal information.
                </li>
                <li>Right to obtain a copy of your personal information. </li>
                <li>
                  Right to object to the processing of your personal
                  information.
                </li>
                <li>
                  Right to ask us to send your personal information directly to
                  another service provider.{" "}
                </li>
              </ul>
              <h3>Changes to the Privacy Policy</h3>
              <p>
                We may modify this Privacy Policy from time to time. So, you’re
                advised to visit this page frequently to stay updated with the
                changes in our Privacy Policy.{" "}
              </p>
              <h3>Contact us</h3>
              <p>
                If you have any questions or queries regarding our Privacy
                Policy, please feel free to contact us at{" "}
                <a href="mailto:info@car-insurance-services.co.uk">info@car-insurance-services.co.uk</a>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="copy-right01">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <p className="colpyy">
              © Copyright 2024 <a href="https://car-insurance-services.co.uk">car-insurance-services.co.uk</a>. All
              Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Privacypolicy;
